export default {
  api: {
    operationSucceed: 'Thao tác thành công',
    operationFailed: 'Thao tác thất bại',
    errorTip: 'Nhắc nhở lỗi',
    errorMessage: 'Thao tác thất bại, hệ thống bất thường!',
    timeoutMessage: 'Đăng nhập quá giờ, hãy đăng nhập lại!',
    apiTimeoutMessage: 'Cổng yêu cầu quá giờ, hãy làm mới giao diện thử lại!',
    apiRequestFailed: 'Yêu cầu lỗi, hãy thử lại sau',
    networkException: 'Mạng bất thường',
    networkExceptionMsg: 'Mạng bất thường, hãy kiểm tra liên kết mạng của bạn có ổn định không!',

    errMsg401: 'Người dùng không có quyền hạn (lệnh bài, tên người dùng, mật mã lỗi)!',
    errMsg403: 'Người dùng được ủy quyền, nhưng bị cấm truy cập!',
    errMsg404: 'Yêu cầu lỗi, chưa tìm được nguồn này!',
    errMsg405: 'Yêu cầu lỗi, cách yêu cầu chưa được cho phép!',
    errMsg408: 'Yêu cầu quá giờ!',
    errMsg500: 'Lỗi máy chủ, hãy liên hệ quản trị viên!',
    errMsg501: 'Mạng chưa được!',
    errMsg502: 'Lỗi mạng!',
    errMsg503: 'Dịch vụ không khả dụng, mãy chủ quá tải hoặc bảo trì!',
    errMsg504: 'Mạng quá giờ!',
    errMsg505: 'Phiên bản http không hỗ trợ yêu cầu này!',
  },
  exception: {
    backLogin: 'Quay lại Đăng nhập',
    backHome: 'Quay lại Trang đầu',
    subTitle403: 'Rất tiếc, bạn không có quyền truy cập giao diện này.',
    subTitle404: 'Rất tiếc, giao diện bạn truy cập không tồn tại.',
    subTitle500: 'Rất tiếc, máy chủ báo lỗi.',
    noDataTitle: 'Trang hiện tại không có dữ liệu',
    networkErrorTitle: 'Lỗi mạng',
    networkErrorSubTitle: 'Rất tiếc, mạng của bạn đã ngắt kết nối, hãy kiểm tra lại!',
  },
  app: {
    title: 'Cửa hàng Ace',
    description: 'Cửa hàng Ace Nạp game, Nạp Dmen, Nạp xu',
    tip: 'Gợi ý',
    copyright: 'Ace Playing',
    icp: 'Kinh ICP số 20009004',
    address: 'Địa chỉ công ty: Số 28 đường Đông, Bắc vành đai 3, khu Triều Dương, TP Bắc Kinh',
    account: 'Tài khoản của tôi',
    changePwd: 'Sửa mật mã',
    chargeRecord: 'Nhật ký nạp',
    helpCenter: 'Trung tâm hỗ trợ',
    chargeNow: 'Nạp ngay',
  },
  login: {
    email: 'E-mail',
    verifyCode: 'Mã xác nhận',
    password: 'Mật mã',
    passwordHolder: 'Hãy nhập tổ hợp chữ cái và chữ số từ 8 - 30 ký tự',
    loginButton: 'Đăng nhập',
    freeAccount: 'Đăng ký miễn phí',
    freeAccountCreate: 'Tạo tài khoản miễn phí',
    forgetPassword: 'Quên mật mã?',
    findPassword: 'Tìm lại mật mã',
    findEmailSent:
      'Thư điện tử tìm lại mật mã đã được gửi vào mail của bạn, hãy nhấn link trong mail để cài đặt lại mật mã',
    forgetFormTitle: 'Cài đặt lại mật mã',
    oldPassword: 'Mật mã cũ',
    newPassword: 'Mật mã mới',
    repeatPassword: 'Hãy nhập lại mật mã',
    policyCheck: 'Hãy đồng ý',
    policy: '《Thỏa thuận quyền riêng tư của Ace》',
    hadAccount: 'Đã có tài khoản?',
    backSignIn: 'Trở về trang đăng nhập',

    logoutButton: 'Đăng xuất',
    registerButton: 'Đăng ký',

    otherSignIn: 'Cách đăng nhập khác',

    // notify
    loginSucceed: 'Đăng nhập thành công',
    logoutSucceed: 'Bạn đã thoát tài khoản hiện tại',
    registerSucceed: 'Đăng ký thành công',
    changePwdSucceed: 'Sửa mật mã thành công',
    resetPwdSucceed: 'Cài đặt lại mật mã thành công',

    // ValidateMsg
    emailValidateMsg: 'Hãy nhập chính xác địa chỉ mail',
    passwordValidateMsg: 'Hãy nhập tổ hợp chữ cái và chữ số từ 8 - 30 ký tự',
    verifySentMsg: 'Đã gửi mã xác nhận',
    verifyValidateMsg: 'Hãy nhập 6 chữ số mã xác nhận',
    policyValidateMsg: 'Chỉ sau khi đồng ý đăng ký',
    diffPwdValidateMsg: 'Hai lần nhập mật mã không đồng nhất',
  },
  help: {
    helpModalTitle: 'Trung tâm hỗ trợ',
    helpTipsTitle: 'Nhắc nhở',
    helpTips_1: 'Đạo cụ ảo có được khi nạp trên nền tảng này chỉ dùng cho "D-MEN：The Defenders".',
    helpTips_2:
      'Khi bạn đặt hàng, hãy đọc kỹ và xác nhận các thông tin như tên, giá, loại tiền, số lượng, quy cách, chi tiết sản phẩm, quy trình đặt hàng, tin gửi, cách liên hệ, cách thanh toán của thương phẩm muốn mua. Để tránh tổn thất cho bạn, xin đừng tiết lộ thông tin cá nhân cho người khác.',
    serviceTitle: 'Dịch vụ Mô tả',
    service_1:
      'Dịch vụ này chỉ dành cho người dùng đã kết nối ứng dụng "D-MEN：The Defenders". Người dùng chưa kết nối cần hoàn thành thao tác kết nối trong game mới được sử dụng dịch vụ này.',
    service_2:
      'Quan trọng: Sau khi nhập tài khoản, hãy đối chiếu kỹ thông tin tài khoản hiển thị, xác nhận không có sai sót gì rồi hãy tiến thành thao tác tiếp theo! (Nếu xuất hiện tình trạng thành toán nhầm tài khoản, chúng tôi sẽ không thể tiến hành hoàn tiền cho bạn, mong bạn hiểu cho!)',
    service_3:
      'Sau khi thanh toán thành công, hệ thống sẽ phát đạo cụ ảo tương ứng vào tài khoản nạp của bạn, hãy kiểm tra trong game. Nếu bạn nạp thành công mà sau 30 phút chưa nhận được đạo cụ ảo đã mua, hãy liên hệ với CSKH qua các cách sau, chúng tôi sẽ xử lý cho bạn kịp thời.',
    contactTitle: 'Danh sách CSKH',
    contactEmail: 'Mail CSKH',
    contactFacebook: 'Link Facebook',
    contactDiscord: 'Link Discord',
  },
  charge: {
    title: 'Danh sách thương phẩm',
    description: 'Cửa hàng Ace Nạp game, Nạp Dmen, Nạp xu',
    coinSelect: 'Chọn loại tiền',
    merchandiseList: 'Danh sách thương phẩm trong game',
    buyNow: 'Mua ngay',
    quickLimit: 'Mua nhanh hạn giờ',
    buyLimit: 'Mỗi nhân vật hạn mua {limit} chiếc',
    chooseRole: 'Chọn nhân vật',
    server: 'Máy chủ',
    serverIdHolder: 'Vui lòng nhập tên máy chủ',
    roleID: 'ID nhân vật',
    roleIDHolder: 'Hãy nhập ID nhân vật',
    searchResult: 'Kết quả tìm kiếm',
    searchAgain: 'Tìm kiếm lại',
    addRole: 'Thêm nhân vật',
    roleInfo: 'Thông tin nhân vật',
    roleLevel: 'Cấp {level}',
    gameName: 'Tên game',
    action: 'Thao tác',
    confirmDel: 'Có xóa không?',
    paymentWay: 'Cách thanh toán',
    paymentAmount: 'Tiền thanh toán',
    roleLimit: 'Nhân vật này còn được mua: ',
    payNow: 'Thanh toán ngay',
    payJumpTips: 'Chuyển đến nền tảng thanh toán bên thứ 3 ngay, hãy đợi chút...',
    paid: 'Đã hoàn thành thanh toán',
  },
  orders: {
    title: 'Danh sách đơn hàng',
    description: 'Cửa hàng Ace Nạp game, Nạp Dmen, Nạp xu',
    ordersTitle: 'Danh sách đơn hàng',
    searchDate: 'Kiểm tra ngày tháng',
    selectGame: 'Chọn game',
    allGame: 'Tất cả game',
    orderNo: 'Mã đơn hàng',
    gameName: 'Tên game',
    merchandiseName: 'Tên thương phẩm',
    merchandiseDetail: 'mục chi tiết',
    orderAmount: 'Giá trị đơn hàng',
    createAt: 'Thời gian lập',
    orderStatus: 'Trạng thái đơn hàng',
    statusGoing: 'Đang thanh toán',
    statusSucceed: 'Thanh toán thành công',
    statusUnknown: 'Vui lòng truy cập hồ sơ nạp tiền để xem kết quả thanh toán',
    statusFailed: 'Thanh toán thất bại',
    action: 'Thao tác',
    viewDetail: 'Xem chi tiết',
    detailTitle: 'Chi tiết đơn hàng',
    backToOrders: 'Quay lại danh sách đơn hàng',
    orderInfo: 'Thông tin đơn hàng',
    merchandiseInfo: 'Thông tin thương phẩm',
    price: 'Đơn giá',
    quantity: 'Số lượng',
    discount: 'Ưu đãi',
    payResult: 'Kết quả thanh toán',
    viewOrder: 'Xem đơn hàng',
  },
  order: {
    title: 'Chi tiết đơn hàng',
    description: 'Chi tiết đơn hàng',
  },
  notFound: {
    title: '404',
    description: '404',
  },
  result: {
    title: 'Kết quả thanh toán',
    description: 'Kết quả thanh toán',
  },
};
